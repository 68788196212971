import api from "@/services/apiService"

class RoundService {

  getRoundSetting(roundId, silent=false) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_MAIN + 'round-setting/' + roundId, {silent})
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  updateRoundReceive(data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_API_MAIN + `round-setting/receive`, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  updateCloseTime(data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_API_MAIN + `round-setting/close-time`, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  saveReceiveNumber(data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_API_MAIN + `round-setting/receive-number`, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  deleteNumbers(data) {
    return new Promise((resolve, reject) => {
      api.patch(process.env.VUE_APP_API_MAIN + `round-setting/receive-number`, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  getRoundLimitNumbers(roundId) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_MAIN + 'round-setting/' + roundId + '/limit')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  /**
   * ผลการออกรางวัล
   */
  getResults(roundDate) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_MAIN + 'round/result/' + roundDate)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  /**
   * รายการโพย
   */
  getTickets(roundId, data, silent) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_API_WRITE_TICKET + 'round/ticket/' + roundId, data, {silent})
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  /**
   * ยกเลิกโพย
   */
  cancelTicket(ticketId, data) {
    return new Promise((resolve, reject) => {
      api.patch(process.env.VUE_APP_API_WRITE_TICKET + 'round/cancel-ticket/' + ticketId, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  /**
   * บันทึกอัตราจ่ายรายตัว
   */
  saveSettingNumbers(roundId, data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_API_MAIN + `round-setting/${roundId}/numbers`, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  /**
   * ลบอัตราจ่ายรายตัว
   */
  deleteSettingNumbers(roundId, data) {
    return new Promise((resolve, reject) => {
      api.patch(process.env.VUE_APP_API_MAIN + `round-setting/${roundId}/numbers`, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  /**
   * บันทึกอัตราจ่ายเฉพาะงวด
   */
  saveFixRate(roundId, data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_API_MAIN + `round-setting/${roundId}/fixrate`, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  /**
   * ตั้งเวลาปิดรับหวยชุด
   */
  // updateLottosetCloseTime(data) {
  //   return new Promise((resolve, reject) => {
  //     api.post(process.env.VUE_APP_API_MAIN + `round-setting/lottoset-time`, data)
  //     .then(function(response) {
  //       resolve(response.data)
  //     })
  //     .catch(function(error) {
  //       reject(error)
  //     })
  //   })
  // }

  /**
   * ตั้งค่าหวยชุด ราคา/ส่วนลด/เงินราวัล
   */
  // updateLottosetSetting(roundId, data) {
  //   return new Promise((resolve, reject) => {
  //     api.put(process.env.VUE_APP_API_MAIN + `round-setting/${roundId}/lottoset`, data)
  //     .then(function(response) {
  //       resolve(response.data)
  //     })
  //     .catch(function(error) {
  //       reject(error)
  //     })
  //   })
  // }

  /**
   * ประวัติการตั้งค่า
   */
  getRoundSettingHistory(roundId) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_MAIN + 'logger/round-setting/' + roundId)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  /**
   * การตั้งค่าหวยชุด
   */
  getSetsRoundSetting(roundId, silent=false) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_MAIN + 'round-setting/sets/' + roundId, {silent})
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  /**
   * บันทึกการตั้งค่ารับของหวยชุด
   */
  updateSetsReceive(data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_API_MAIN + `round-setting/sets-receive`, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  /**
   * บันทึกตั้งค่ารับของรายตัว
   */
  saveSetsReceiveNumber(data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_API_MAIN + `round-setting/sets-receive-number`, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  deleteSetsNumbers(data) {
    return new Promise((resolve, reject) => {
      api.patch(process.env.VUE_APP_API_MAIN + `round-setting/sets-receive-number`, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  /**
   * ตั้งค่าประเภทอัตราจ่าย
   */
  activateRateType(roundId, data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_API_MAIN + `round-setting/${roundId}/ratetype`, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  /**
   * ตั้งค่าอัตราจ่ายแบบลดขั้น
   */
  saveStepRate(roundId, data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_API_MAIN + `round-setting/${roundId}/steprate`, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }
}

export default new RoundService()
